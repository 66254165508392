<template>
	<div class="data">
		<div class="data_main">
			<div class="dm_head">
				<div class="dmh_top">
					<div class="dmht_left">
						<span>设备最新数据</span>
					</div>
					<div class="dmht_right">
						<span>{{getTime(lastData.data_time)}}</span>
					</div>
				</div>
				<div class="dmh_bottom">
					<div class="dmhb_left">
						<span>{{property_name}}</span>
						<span>{{unit}}</span>
					</div>
					<div class="dmhb_right">
						<span>{{lastData.value}}</span>
					</div>
				</div>
			</div>

			<div class="dm_data_more">
				<div class="ddm_title">
					<span>{{property_name}}走势图</span>
					<span>单位：{{unit}}</span>
				</div>
				<div class="tu_data" id="dataindex">

				</div>
			</div>

			<div class="dm_data_warning">
				<div class="dmdw_title">
					<span>设备报警范围</span>
					<div class="dmdw_btn" @click="toAddWarning">
						<i class="iconfont icon-xinzeng"></i>
						<span>新增</span>
					</div>
				</div>
				<div class="dmdw_list">
					<template v-for="item in warningList">
						<div class="dmdwl_div" :key="item.id">
							<div class="dmdwld_head">
								<div class="dd_left">
									<div class="ddl_tag" v-if="item.warning_type=='none'">
										<span>正常</span>
									</div>
									<div class="ddl_tag" style="background:#FFA14E" v-if="item.warning_type=='low'">
										<span>提醒</span>
									</div>
									<div class="ddl_tag" style="background:#FF5A4E" v-if="item.warning_type=='high'">
										<span>报警</span>
									</div>
									<div class="ddl_name">
										<span>{{deviceName}}</span>
									</div>
								</div>
								<div class="dd_right" >
									<i class="iconfont icon-bianji" @click="toEditWarning(item)"></i>
									<i class="iconfont icon-shanchu1" @click="deleteWarningDialog(item)"></i>
								</div>
							</div>
							<div class="dmdwld_content">
								<div class="dmdwldc_div">
									<span>{{property_name}}最小值</span>
									<span>{{item.min_value}}{{unit}}</span>
								</div>
								<div class="dmdwldc_div">
									<span>{{property_name}}最大值</span>
									<span>{{item.max_value}}{{unit}}</span>
								</div>

								<div class="dmdwldc_div">
									<span style="color:#999999">设置时间：{{item.create_time_str}}</span>
									<span></span>
								</div>
							</div>
							
						</div>
					</template>
					
				</div>
			</div>
		</div>


	</div>
</template>

<script>


import studio_login_api from '@/api/teaching_studio_no_login.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import until from '@/api/until.js'
import * as echarts from 'echarts';
export default {
	name: 'Home',
	data() {
		return {
			lastData:{},
			unit:"",
			warningList:[],
			deviceName:'',
			property_name:'',
			property_key:"",
		}
	},
	components: {

	},
	mounted() {
		document.title="更多数据"
		this.getNewData();
		this.getWarningList();

		this.getMoreData();
		this.unit=this.$route.query.unit
		this.property_key=this.$route.query.property_key
		this.deviceName=this.$route.query.deviceName
	},
	methods: {
		//获取当前属性的最新数据
		async getNewData(){
			let app_id=this.$root.app_id;
			let property_id=this.$route.query.property_id
			let data=await studio_api.Teaching_studio_apiIot_device_propertyDataLastGet({app_id,property_id});
			if(data.errcode==0){
				this.lastData=data.other;
			}
		},
		getTime(time){
			if(time){
				return until.formatDateHour(time*1000)
			}
			else{
				return ""
			}
			
		},
		//获取报警设置列表
		async getWarningList(){
			let app_id=this.$root.app_id;
			let property_id=this.$route.query.property_id
			let warning_type=""
			let data=await studio_api.Teaching_studio_apiIot_device_property_warningListGet({app_id,property_id,warning_type});
			if(data.errcode==0){
				this.warningList=data.other.list;
			}
		},
		deleteWarningDialog(item){
			this.$dialog.confirm({
				title: '标题',
				message: '是否确认删除',
			})
			.then(async () => {
				this.deleteWarning(item);
				
			})
			.catch(() => {
				// on cancel
			});
		},

		//删除报警设置
		async deleteWarning(item){
			let app_id=this.$root.app_id;
			let property_warning_id=item.id
			this.$toast.loading({
				message: "加载中...",
				forbidClick: false,
				duration: 0
			});
			let data=await studio_api.Teaching_studio_apiIot_device_property_warningDelDelete({app_id,property_warning_id});
			if(data.errcode==0){
				this.$toast("删除成功")
				this.getWarningList();
			}
		},

		async toAddWarning(){
			//获取更多数据
		
			let app_id=this.$root.app_id;
            let product_key=""
            let property_key=this.property_key
            let name=""
			this.$toast.loading({
				message: "加载中...",
				forbidClick: false,
				duration: 0
			});
			let data=await studio_api.Teaching_studio_apiWarning_range_quickListGet({app_id,product_key,property_key,name});
			if(data.errcode==0){
				this.$toast.clear()
				if(data.other.list.length>0){
					this.$router.push("/addshortselect?property_id="+this.$route.query.property_id+"&property_key="+this.property_key+"&property_name="+this.property_name);
				}
				else{
					this.$router.push("/addwarning?property_id="+this.$route.query.property_id+"&property_key="+this.property_key+"&property_name="+this.property_name+"&back_index=1");
				}
             
				
			}



			

		},
		toEditWarning(item){
			this.$router.push("/editwarning?property_id="+this.$route.query.property_id+"&property_key="+this.property_key+"&property_warning_id="+item.id+"&property_name="+this.property_name);
		},
		//获取更多数据
		async getMoreData(){
			let app_id=this.$root.app_id;
			let property_id=this.$route.query.property_id
			let begin_time=parseInt(until.getAddDayTime(-30)/1000) ;
			let end_time=parseInt(until.getNowDayTime()/1000);
			let data=await studio_api.Teaching_studio_apiIot_device_propertyTimeDataGet({app_id,property_id,begin_time,end_time});
			if(data.errcode==0){
				this.property_name=data.other.name
				this.setLineTu(data.other.data);
			}
		},

		//设置图表数据
		setLineTu(data){
			var myChart = echarts.init(document.getElementById('dataindex'));
			let xData=[];
			let yData=[];
			data.map(res=>{
				xData.push(until.formatDateNoYear(res.data_time*1000))
				yData.push(res.value)
			})
			// 绘制图表
			myChart.setOption({
				title: {
					text: 'ECharts 入门示例',
					show:false
				},
				tooltip: {},
				grid:{
					top:'15',
					let:35,
					bottom:30,
					right:15,
				},
				xAxis: {
					data: xData,
					axisTick: {
						show: false,  //刻度线
					},
					axisLine: {
						show: false, //隐藏y轴
					},
					
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
						},
					},
				},
				yAxis: {
					type: 'value',
				
					min: 0,
					axisTick: {
						show: false,  //刻度线
					},
					axisLine: {
						show: false, //隐藏y轴
					},
					axisLabel: {
						show: false, //隐藏刻度值
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
						},
					},
				


				},
				series: [
					{
						data: yData,
						type: 'line',
						smooth: true
					}
				]
			});
		},
	}
}
</script>
<style scoped>
.data{
	width: 100%;
	background: #F6F6F6;
	height: 100vh;
	display: inline-block;
	overflow: auto;

	box-sizing: border-box;
}
.data_main{
	width: 100%;
	display: inline-block;
	position: relative;
	height: 100vh;
	padding: 0 30px;
	box-sizing: border-box;
}
.dm_head{
	width: 100%;
	height: 240px;
	background: linear-gradient(180deg, #4E89FF 0%, #4EB8FF 100%);
	border-radius: 20px 20px 20px 20px;
	opacity: 1;
	margin-top: 24px;
	display: inline-block;
}
.dmh_top{
	width: 100%;
	padding: 0 24px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
}
.dmht_left{
	font-size: 28px;
	color: #FFFFFF;
	line-height: 40px;
	font-weight: bold;
	
}
.dmht_right{
	font-size: 24px;
	color: #FFFFFF;
	line-height: 33px;
}
.dmh_bottom{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;
	box-sizing: border-box;
	margin-top: 50px;
}
.dmhb_left{
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	font-size: 32px;
	color: #FFFFFF;
	font-weight: bold;
	line-height: 45px;
}
.dmhb_left span:last-child{
	font-size: 24px;
	color: #FFFFFF;
	line-height: 33px;
	margin-top: 16px;
}
.dmhb_right{
	font-size: 64px;
	color: #FFFFFF;
	font-weight: bold;
	line-height: 78px;

}
.dm_data_more{
	width: 100%;
	height: 533px;
	background: #FFFFFF;
	border-radius: 20px 20px 20px 20px;
	opacity: 1;
	margin-top: 24px;
	display: inline-block;
}
.ddm_title{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;
	box-sizing: border-box;
	margin-top: 30px;
	font-size: 32px;
	color: #333333;

}
.ddm_title span:last-child{
	font-size: 24px;
	color: #999999;
	line-height: 33px;

}
.dm_data_warning{
	width: 100%;
	background: #FFFFFF;
	border-radius: 20px 20px 20px 20px;
	opacity: 1;
	margin-top: 24px;
	display: inline-block;
	padding-bottom: 24px;
	box-sizing: border-box;
}
.dmdw_title{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 32px;
	color: #333333;
	font-weight: bold;
	margin-top: 30px;
	padding: 0 24px;
	box-sizing: border-box;
}
.dmdw_btn{
	width: 152px;
	height: 64px;
	background: #F6F6F6;
	border-radius: 32px 32px 32px 32px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.dmdw_btn .iconfont{
	font-size: 28px;
	color: #4E89FF;
}
.dmdw_btn span{
	font-size: 28px;
	color: #4E89FF;
	margin-left: 8px;
}
.dmdw_list{
	width: 100%;
	display: inline-block;
	padding: 0 24px;
	box-sizing: border-box;

}
.dmdwl_div{
	width: 100%;

	background: #F6F6F6;
	border-radius: 20px 20px 20px 20px;
	padding: 0 30px;
	box-sizing: border-box;
	display: inline-block;
	margin-top: 24px;
}
.dmdwld_head{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
}
.dd_left{
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.ddl_tag{
	width: 120px;
	height: 48px;
	background: #02E4AE;
	border-radius: 8px 8px 8px 8px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	color: #FFFFFF;
	font-weight: bold;
}
.ddl_name{
	font-size: 28px;
	color: #333333;
	font-weight: bold;
	margin-left: 24px;
	line-height: 40px;
}
.dd_right .iconfont{
	font-size: 32px;
	color: #4E89FF;
}
.dd_right .iconfont:last-child{
	color: #FF5A4E;
	margin-left: 40px;
}
.dmdwld_content{
	width: 100%;
	display: inline-block;
	margin-top: 16px;	
	padding: 0 30px;
	box-sizing: border-box;
}
.dmdwldc_div{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px dashed #DFDFDF;
	font-size: 28px;
	color: #333333;
	line-height: 40px;
	height: 93px;
}
.dmdwldc_div span:last-child{
	font-size: 32px;
	color: #333333;
	font-weight: bold;
	line-height: 45px;
}
.dmdwldc_div:last-child{
	border-bottom: 0px;
}
.tu_data{
	width: 100%;
	height: 393px;
}
</style>

